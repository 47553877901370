<template>
  <q-card flat class="search-widget bg-transparent">
    <q-tabs
      v-model="searchTab"
      align="left"
      narrow-indicator
      indicator-color="secondary"
      dense
    >
      <q-tab name="make_model" label="Make/Model" :ripple="false" />
      <q-tab name="body_type" label="Body Type" :ripple="false" />
    </q-tabs>

    <q-tab-panels v-model="searchTab" animated class="bg-transparent">
      <q-tab-panel
        name="make_model"
        class="search-widget__side-panel"
        style="overflow: hidden !important;"
      >
        <q-form @submit="submitSearch('makeModel')">
          <q-card class="search-widget__inputs-container">
            <q-select
              v-if="windowWidth > 1025"
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              v-model="condition"
              :options="conditionOptions"
              map-options
              emit-value
              label="Condition"
              class="full-width no-wrap ellipsis"
            />
            <q-separator
              v-if="windowWidth > 1025"
              vertical
              inset
              class="q-mx-md"
            />
            <q-select
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              v-model="makeId"
              :options="makeOptions"
              map-options
              emit-value
              label="Make"
              class="full-width"
            >
              <template v-slot:selected-item="scope">
                <span class="ellipsis">{{ scope.opt.label }}</span>
              </template>
            </q-select>
            <q-separator
              v-if="windowWidth > 1025"
              vertical
              inset
              class="q-mx-md"
            />
            <q-select
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              label="Model"
              v-model="modelId"
              :options="modelOptions"
              option-value="value"
              option-label="label"
              option-disable="disable"
              emit-value
              map-options
              class="full-width"
            >
              <template v-slot:selected-item="scope">
                <span class="ellipsis">{{ scope.opt.label }}</span>
              </template>
            </q-select>
            <q-separator
              v-if="windowWidth > 1025"
              vertical
              inset
              class="q-mx-md"
            />
            <q-input
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              v-model="postalCode"
              label="Zip Code"
              inputmode="numeric"
              pattern="[0-9]*"
              mask="#####"
              :error="postalCodeError != null"
              class="full-width q-pb-none"
            >
              <template v-slot:prepend>
                <q-icon name="sym_r_place" />
              </template>
              <template v-slot:append>
                <q-btn
                  v-if="windowWidth <= 1025"
                  dense
                  label="Go"
                  type="submit"
                  color="secondary"
                  class="q-my-sm q-ml-sm q-px-xs"
                  :disable="disableSearch"
                  :loading="disableSearch"
                  @click="submitSearch('makeModel')"
                />
              </template>
            </q-input>
            <q-btn
              v-if="windowWidth > 1025"
              label="Go"
              type="submit"
              color="secondary"
              class="q-my-sm q-ml-md"
              :disable="disableSearch"
              :loading="disableSearch"
            />
          </q-card>
        </q-form>
      </q-tab-panel>
      <q-tab-panel
        name="body_type"
        class="search-widget__side-panel"
        style="overflow: hidden !important;"
      >
        <q-form @submit="submitSearch('bodyType')">
          <q-card flat class="search-widget__inputs-container">
            <q-select
              v-if="windowWidth > 1025"
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              v-model="condition"
              :options="conditionOptions"
              map-options
              emit-value
              label="Condition"
              class="full-width"
            >
              <template v-slot:selected-item="scope">
                <span class="ellipsis">{{ scope.opt.label }}</span>
              </template>
            </q-select>
            <q-separator
              v-if="windowWidth > 1025"
              vertical
              inset
              class="q-mx-md"
            />
            <q-select
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              v-model="bodyType"
              :options="bodyTypeOptions"
              map-options
              emit-value
              label="Body Type"
              class="full-width"
              :style="[windowWidth <= 1025 ? { 'grid-column': '1/-1' } : {}]"
            >
              <template v-slot:selected-item="scope">
                <span class="ellipsis">{{ scope.opt.label }}</span>
              </template>
            </q-select>
            <q-separator
              v-if="windowWidth > 1025"
              vertical
              inset
              class="q-mx-md"
            />
            <q-input
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              v-model="postalCode"
              label="Zip Code"
              inputmode="numeric"
              pattern="[0-9]*"
              mask="#####"
              :error="postalCodeError != null"
              class="full-width q-pb-none"
            >
              <template v-slot:prepend>
                <q-icon name="sym_r_place" />
              </template>
              <template v-slot:append>
                <q-btn
                  v-if="windowWidth <= 1025"
                  dense
                  label="Go"
                  type="submit"
                  color="secondary"
                  class="q-my-sm q-ml-sm q-px-xs"
                  :disable="disableSearch"
                  :loading="disableSearch"
                  @click="submitSearch('bodyType')"
                />
              </template>
            </q-input>
            <q-btn
              v-if="windowWidth > 1025"
              label="Go"
              type="submit"
              color="secondary"
              class="q-my-sm q-ml-md"
              :disable="disableSearch"
              :loading="disableSearch"
            />
          </q-card>
        </q-form>
      </q-tab-panel>
    </q-tab-panels>
  </q-card>
</template>

<script>
export default {
  name: "SearchPanel",
  data() {
    return {
      searchTab: "make_model",
      disableSearch: false,
      condition: "",
      bodyType: "",
      makeId: "",
      modelId: "",
      postalCode: this.$store.state.search.location.postalCode,
      postalCodeError: null
    };
  },
  computed: {
    searchFilterModels() {
      return this.$store.state.search.filter.models;
    },
    conditionOptions() {
      return [
        { label: "All Conditions", value: "" },
        { label: "New", value: "New" },
        { label: "Used", value: "Used" }
      ];
    },
    bodyTypeOptions() {
      return [
        { label: "All Body Types", value: "" },
        {
          label: "Convertible",
          value: "Convertible"
        },
        {
          label: "Coupe",
          value: "Coupe"
        },
        {
          label: "Hatchback",
          value: "Hatchback"
        },
        {
          label: "Sedan",
          value: "Sedan"
        },
        {
          label: "SUV",
          value: "SUV"
        },
        {
          label: "Truck",
          value: "Truck"
        },
        {
          label: "Van",
          value: "Van"
        },
        {
          label: "Wagon",
          value: "Wagon"
        }
      ];
    },
    makeOptions() {
      return this.$store.getters["vehicleMakeModels/makeOptionsList"];
    },
    modelOptions() {
      let modelOptions = [{ label: "All Models", value: "" }];

      if (this.makeId != "") {
        this.$store.state.vehicleMakeModels.makeIdModelIdsMap[
          this.makeId.toString()
        ].forEach(modelId => {
          modelOptions.push({
            label: this.$store.state.vehicleMakeModels.modelIdNameMap[modelId],
            value: modelId
          });
        });
      }
      return modelOptions;
    }
  },
  mounted() {},
  methods: {
    submitSearch(type) {
      this.disableSearch = true;

      this.$store
        .dispatch("geolocation/VALIDATE_POSTAL_CODE", this.postalCode)
        .then(locationData => {
          this.postalCodeError = null;

          this.$store.commit("search/SET_LOCATION", {
            postalCode: this.postalCode,
            city: locationData["city"],
            state: locationData["state"]
          });

          if (this.condition != "") {
            this.searchFilterModels.condition.push(this.condition);
          } else {
            this.searchFilterModels.condition = [];
          }

          if (type === "makeModel") {
            this.searchFilterModels.bodyType = [];

            if (this.modelId != "") {
              this.searchFilterModels.makeId = [];
              this.searchFilterModels.modelId.push(this.modelId);
            } else if (this.makeId != "") {
              this.searchFilterModels.modelId = [];
              this.searchFilterModels.makeId.push(this.makeId);
            } else {
              this.searchFilterModels.makeId = [];
              this.searchFilterModels.modelId = [];
            }
          } else if (type === "bodyType") {
            this.searchFilterModels.makeId = [];
            this.searchFilterModels.modelId = [];

            if (this.bodyType != "") {
              this.searchFilterModels.bodyType.push(this.bodyType);
            } else {
              this.searchFilterModels.bodyType = [];
            }
          }

          this.$store.commit("search/SET_FILTER_MODELS_ACTIVE");
          this.$store.commit("search/SET_LOADING");

          this.$store.state.search.sort.model =
            "advanced.popular_on_carscience";

          this.$router.push({ path: "/search" });
        })
        .catch(error => {
          this.postalCodeError = error;
          this.disableSearch = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.search-widget {
  width: 100%;

  .search-widget__side-panel {
    padding: $space-md 0;
  }

  .search-widget__inputs-container {
    @include display-flex(row, flex-start, items-center);
    padding: 0px $space-md;
    background: color(background, light);

    @include screen-md {
      background: color(background);
      padding: $space-md;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: $space-md;

      .q-field {
        &:nth-child(even) {
          grid-column: 0/1;
        }
        &:nth-child(odd) {
          grid-column: 1/2;
        }
        &:last-child {
          grid-column: 1/-1;
        }
      }
    }
  }
}
</style>
